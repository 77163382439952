export default function AttackerWantComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-3">
                <h3 className="aifence-text-color" style={{fontSize: "18px", lineHeight: "28px", marginTop: "30px"}}>
                    What does the attacker want?
                </h3>

                <h4 className="aifence-text-color" style={{fontSize: "18px", lineHeight: "28px", marginTop: "20px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li-small"> Violating the security of the machine learning model: integrity,
                            availability and privacy violations
                            <ul className="aifence-ul">
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Integrity</span>: attackers
                                    can prevent their detection without
                                    compromising the normal system operation.
                                </li>
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Availability</span>:
                                    attackers can compromise the normal system
                                    operation.
                                </li>
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Privacy</span>: attackers
                                    aim at obtaining privacy information about
                                    the system, users or data.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"> Specifying the target samples:
                            <ul className="aifence-ul">
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Target-specific</span>: the
                                    attacker targets a specific set of samples.

                                </li>
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Target-generic</span>: the
                                    attacker targets aims at affecting any sample.

                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"> Influencing the model’s prediction to contain errors:
                            <ul className="aifence-ul">
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Error-specific</span>: the
                                    attacker wants to misclassify a sample as
                                    a specific class.
                                </li>
                                <li className="aifence-li-small"><span style={{color: "#bd1b14"}}>Error-generic</span>: the
                                    attacker want to misclassify a sample as
                                    any class different from the true class.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </h4>
            </div>
            <div className="d-none d-xl-block" style={{padding: "100px"}}>

                <h3 className="aifence-text-color" style={{lineHeight: "36px"}}>
                    What does the attacker want?
                </h3>

                <h4 className="aifence-text-color" style={{lineHeight: "36px", marginTop: "50px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li"> Violating the security of the machine learning model: integrity,
                            availability and privacy violations
                            <ul className="aifence-ul">
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Integrity</span>: attackers
                                    can prevent their detection without
                                    compromising the normal system operation.
                                </li>
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Availability</span>:
                                    attackers can compromise the normal system
                                    operation.
                                </li>
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Privacy</span>: attackers
                                    aim at obtaining privacy information about
                                    the system, users or data.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"> Specifying the target samples:
                            <ul className="aifence-ul">
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Target-specific</span>: the
                                    attacker targets a specific set of samples.

                                </li>
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Target-generic</span>: the
                                    attacker targets aims at affecting any sample.

                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"> Influencing the model’s prediction to contain errors:
                            <ul className="aifence-ul">
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Error-specific</span>: the
                                    attacker wants to misclassify a sample as
                                    a specific class.
                                </li>
                                <li className="aifence-li"><span style={{color: "#bd1b14"}}>Error-generic</span>: the
                                    attacker want to misclassify a sample as
                                    any class different from the true class.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </h4>
            </div>
        </div>

    );
}