import NavbarComponent from "../components/NavbarComponent";
import ModelStealingAttackPage from "../components/ModelStealingAttackPage";
import FooterComponent from "../components/FooterComponent";


export default function ModelStealingAttackComponent() {
    return (
        <div className="aifence-website">
            <main>
                <NavbarComponent/>
                <ModelStealingAttackPage/>
                <FooterComponent/>
            </main>
        </div>
    );
}