import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import {Button, Form} from "react-bootstrap";

export const SubscribeEmailJS = () => {
    const form = useRef();
    const form_small = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        console.log(form.current);

        emailjs.sendForm('service_u4v8a5r', 'template_goivpd2', form.current, 'fZ5J6ZS5zZRU38s0M')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                window.location.reload();
            });
    };

    const sendEmailSmall = (e) => {
        e.preventDefault();

        console.log(form_small.current);

        emailjs.sendForm('service_u4v8a5r', 'template_goivpd2', form_small.current, 'fZ5J6ZS5zZRU38s0M')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                window.location.reload();
            });
    };

    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-3">
                <Form ref={form_small} onSubmit={sendEmailSmall}>
                    <h6 className="pt-3 mt-5 white-text"
                        style={{fontSize: "20px", fontFamily: "Calibri Light, sans-serif"}}>
                        Subscribe to know more:
                    </h6>
                    <Form.Group controlId="formEmailSmall">
                        <Form.Label className="white-text"></Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            name="user_email_small"
                            required
                        />
                    </Form.Group>
                    <Button className="mt-4 subscribe-button float-end" variant="primary" type="submit"
                            value="Send">
                        Submit
                    </Button>
                </Form>
            </div>
            <div className="d-none d-xl-block">
                <Form ref={form} onSubmit={sendEmail}>
                    <div className="grid grid_1_3_4_2_1 p-3">
                        <div className="column">
                        </div>
                        <div className="column">
                            <h4 className="mt-4 float-end white-text"
                                style={{fontSize: "24px", fontFamily: "Calibri Light, sans-serif"}}>Subscribe to know
                                more:</h4>
                        </div>
                        <div className="column">
                            <Form.Group controlId="formEmail">
                                <Form.Label className="white-text"></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="user_email"
                                    required
                                />
                            </Form.Group>
                        </div>
                        <div className="column">
                            <Button className="mt-4 subscribe-button float-end" variant="primary" type="submit"
                                    value="Send">
                                Submit
                            </Button>
                        </div>
                        <div className="column">
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};
