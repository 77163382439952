export default function AboutComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-4">
                <p style={{lineHeight: "28px", fontSize: "16px"}}>At <img src={"aifence_white.PNG"} height="30" className="logo-img"
                                                                          style={{marginBottom: "6px"}}/>, we are a team of experts in both
                    security and artificial intelligence.</p>
                <p style={{lineHeight: "28px", fontSize: "16px"}}>We firmly believe that leveraging the potential of AI should never
                    compromise security. Therefore, we have implemented a tool to help your
                    business to take appropriate security measures to safeguard your valuable information.</p>
                <p style={{lineHeight: "28px", fontSize: "16px"}}>It's essential to acknowledge that releasing unsecure AI models in the
                    market can lead to substantial financial losses. That's why our software solution is specifically designed to help
                    your company eliminate any potential risks associated with your AI models, ensuring their resilience and
                    safeguarding your investments. </p>
                <p style={{lineHeight: "28px", fontSize: "16px"}}>We offer an user-friendly solution to make the analysis of security risks
                    on your AI solution fast and intuitive. Furthermore, our team of experts is ready to assist you company during the
                    development cycle of your AI solutions.</p>

            </div>
            <div className="d-none d-xl-block" style={{padding: "100px"}}>
                <p style={{lineHeight: "38px", fontSize: "24px"}}>At <img src={"logo_aifence.png"} height="38" className="logo-img"
                                                                          style={{marginBottom: "6px"}}/>, we are a team of experts in both
                    security and artificial intelligence.</p>
                <p style={{lineHeight: "38px", fontSize: "24px"}}>We firmly believe that leveraging the potential of AI should never
                    compromise security. Therefore, we have implemented a tool to help your
                    business to take appropriate security measures to safeguard your valuable information.</p>
                <p style={{lineHeight: "38px", fontSize: "24px"}}>It's essential to acknowledge that releasing unsecure AI models in the
                    market can lead to substantial financial losses. That's why our software solution is specifically designed to help
                    your company eliminate any potential risks associated with your AI models, ensuring their resilience and
                    safeguarding your investments. </p>
                <p style={{lineHeight: "38px", fontSize: "24px"}}>We offer an user-friendly solution to make the analysis of security risks
                    on your AI solution fast and intuitive. Furthermore, our team of experts is ready to assist you company during the
                    development cycle of your AI solutions.</p>
            </div>
        </div>
    );
}