import React from "react";

export default function DescriptionComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-4">

                <h4 className="pb-2" style={{fontSize: "18px", lineHeight: "28px"}}>
                    Securing a machine learning model against poisoning and perturbation attacks presents a
                    formidable challenge. Frequently, defense methods devised to counter one type of adversarial
                    attack swiftly expose vulnerabilities to novel and diverse attack strategies.
                </h4>

                <h4 className="pb-4" style={{fontSize: "18px", lineHeight: "28px"}}>
                    In light of this intricate scenario, we have developed <b>Knaight</b>, a software solution
                    designed to evaluate the resilience of machine learning models against both
                    <span style={{color: "#bd1b14"}}> poisoning</span> and
                    <span style={{color: "#bd1b14"}}> perturbation</span> attacks. Our software integrates a set
                    of
                    meticulously chosen criteria, enabling a
                    comprehensive and dependable evaluation of a machine learning model's security and its
                    associated
                    safeguards.
                </h4>

                <div className="aifence_nice_box">
                    <img src={"knaight_blue.PNG"} height="40" className="logo-img responsive-img"
                         style={{marginLeft: "50px"}} alt="Logo"/>
                    <h4 className="pb-2 mt-4 aifence-text-color"
                        style={{fontSize: "18px", lineHeight: "36px", textAlign: "left"}}>
                        <b>Knaight</b> stands out as a user-friendly, low-code solution, facilitating
                        rapid and effortless deployment of security assessments.
                    </h4>
                </div>

                <div className="aifence_nice_box mt-5">
                    <h4 className="text-center">Cybersecurity</h4>
                    <h4 className="text-center">Finance</h4>
                    <h4 className="text-center">Healthcare</h4>
                    <h4 className="text-center">Military</h4>
                    <h4 className="text-center">Automotive</h4>
                    <h4 className="text-center">Language Models</h4>
                </div>

            </div>
            <div className="d-none d-xl-block" style={{padding: "100px"}}>

                <h4 className="pb-2" style={{lineHeight: "36px"}}>
                    Securing a machine learning model against poisoning and perturbation attacks presents a
                    formidable challenge. Frequently, defense methods devised to counter one type of adversarial
                    attack swiftly expose vulnerabilities to novel and diverse attack strategies.
                </h4>

                <h4 className="pb-4" style={{lineHeight: "36px", marginBottom: "50px"}}>
                    In light of this intricate scenario, we have developed <b>Knaight</b>, a software solution
                    designed to evaluate the resilience of machine learning models against both
                    <span style={{color: "#bd1b14"}}> poisoning</span> and
                    <span style={{color: "#bd1b14"}}> perturbation</span> attacks. Our software integrates a set
                    of
                    meticulously chosen criteria, enabling a
                    comprehensive and dependable evaluation of a machine learning model's security and its
                    associated
                    safeguards.
                </h4>

                <div className="aifence_nice_box">
                    <div className="grid grid_3_9">
                        <div className="column m-3">
                            <img src={"knaight_blue.PNG"} height="50" className="logo-img responsive-img"
                                 style={{marginLeft: "50px"}} alt="Logo"/>
                        </div>
                        <div className="column">
                            <h4 className="pb-2 aifence-text-color" style={{lineHeight: "36px"}}>
                                <b>Knaight</b> stands out as a user-friendly, low-code solution, facilitating
                                rapid and
                                effortless deployment of security assessments.
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="aifence_nice_box mt-5">
                    <div className="grid grid_4_4_4">
                        <div className="column m-3">
                            <h4 className="text-center">Cybersecurity</h4>
                        </div>
                        <div className="column m-3">
                            <h4 className="text-center">Finance</h4>
                        </div>
                        <div className="column m-3">
                            <h4 className="text-center">Healthcare</h4>
                        </div>
                    </div>
                    <div className="grid grid_4_4_4">
                        <div className="column m-3">
                            <h4 className="text-center">Military</h4>
                        </div>
                        <div className="column m-3">
                            <h4 className="text-center">Automotive</h4>
                        </div>
                        <div className="column m-3">
                            <h4 className="text-center">Language Models</h4>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}