export default function AttackerAchieveComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-3">
                <h3 className="aifence-text-color" style={{fontSize: "18px", lineHeight: "28px", marginTop: "30px"}}>
                    What does the attacker achieve?
                </h3>

                <h4 className="aifence-text-color" style={{fontSize: "18px", lineHeight: "28px", marginTop: "20px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li-small"> This depends on how much the attacker may influence the
                            <span style={{color: "#bd1b14"}}> learning setting</span> and
                            <span style={{color: "#bd1b14"}}> data perturbation</span>.
                        </li>
                        <li className="aifence-li-small"> Influence on <span
                            style={{color: "#bd1b14"}}> learning setting</span>:
                            <ul className="aifence-ul">
                                <li className="aifence-li-small">Training-from-scratch (TFS): the attacker modifies a subpart
                                    of the training data, which is collected and applied by the victim to train and
                                    fine-tune the model.
                                </li>
                                <li className="aifence-li-small">Model-training (MT): the attacker acts as a malicious
                                    third-party trained. In this process, the attacker returns to the victim a trained
                                    model the behaves according to the attacker’s goal. In this case, the victim may
                                    never see the training data and the model presents good validation metrics to be
                                    approved.
                                </li>
                                <li className="aifence-li-small">Fine-tuning (FT): similar to model training, but with a
                                    different purpose. The pre-trained model may already embed vulnerabilities.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"><span style={{color: "#bd1b14"}}> Data perturbation</span>:
                            modifications that are imperceptible and preserves the original semantics of the input
                            samples and class labels.
                        </li>
                    </ul>
                </h4>
            </div>

            <div className="d-none d-xl-block" style={{padding: "100px"}}>

                <h3 className="aifence-text-color" style={{lineHeight: "36px"}}>
                    What does the attacker achieve?
                </h3>

                <h4 className="aifence-text-color" style={{lineHeight: "36px", marginTop: "50px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li"> This depends on how much the attacker may influence the
                            <span style={{color: "#bd1b14"}}> learning setting</span> and
                            <span style={{color: "#bd1b14"}}> data perturbation</span>.
                        </li>
                        <li className="aifence-li"> Influence on <span
                            style={{color: "#bd1b14"}}> learning setting</span>:
                            <ul className="aifence-ul">
                                <li className="aifence-li">Training-from-scratch (TFS): the attacker modifies a subpart
                                    of the training data, which is collected and applied by the victim to train and
                                    fine-tune the model.
                                </li>
                                <li className="aifence-li">Model-training (MT): the attacker acts as a malicious
                                    third-party trained. In this process, the attacker returns to the victim a trained
                                    model the behaves according to the attacker’s goal. In this case, the victim may
                                    never see the training data and the model presents good validation metrics to be
                                    approved.
                                </li>
                                <li className="aifence-li">Fine-tuning (FT): similar to model training, but with a
                                    different purpose. The pre-trained model may already embed vulnerabilities.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"><span style={{color: "#bd1b14"}}> Data perturbation</span>:
                            modifications that are imperceptible and preserves the original semantics of the input
                            samples and class labels.
                        </li>
                    </ul>
                </h4>
            </div>
        </div>
    );
}