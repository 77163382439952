import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import {Button, Form} from "react-bootstrap";

export const ContactUs = () => {
    const form = useRef();
    const form_small = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u4v8a5r', 'template_ju193n9', form.current, 'fZ5J6ZS5zZRU38s0M')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                window.location.reload();
            });
    };
    const sendEmailSmall = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u4v8a5r', 'template_ju193n9', form_small.current, 'fZ5J6ZS5zZRU38s0M')
            .then((result) => {
                console.log(result.text);
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                window.location.reload();
            });
    };

    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-5">
                <h4 className="font-calibri-light">Get in touch to know more about our solution</h4>
                <Form ref={form_small} onSubmit={sendEmailSmall}>
                    <Form.Group controlId="formNameSmall">
                        <Form.Label className="aifence-text-color"></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your name"
                            name="user_name_small"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formEmailSmall">
                        <Form.Label className="aifence-text-color"></Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            name="user_email_small"
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formMessageSmall">
                        <Form.Label className="aifence-text-color"></Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Enter your message"
                            name="message_small"
                            required
                        />
                    </Form.Group>

                    <Button className="mt-1 contact-button float-end" variant="primary" type="submit" value="Send">
                        Submit
                    </Button>
                </Form>
            </div>
            <div className="d-none d-xl-block">
                <div className="grid grid_2_8_2 p-3">
                    <div className="column">
                    </div>
                    <div className="column">
                        <h4 className="font-calibri-light">Get in touch to know more about our solution</h4>
                        <Form ref={form} onSubmit={sendEmail}>
                            <Form.Group controlId="formName">
                                <Form.Label className="aifence-text-color"></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="user_name"
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label className="aifence-text-color"></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="user_email"
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label className="aifence-text-color"></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Enter your message"
                                    name="message"
                                    required
                                />
                            </Form.Group>

                            <Button className="mt-1 contact-button float-end" variant="primary" type="submit"
                                    value="Send">
                                Submit
                            </Button>
                        </Form>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>

        </div>
    );
};
