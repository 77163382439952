import React from 'react';
import './HomePage.css';
import DescriptionComponent from "./DescriptionComponent";
import VulnerabilityComponent from "./VulnerabilityComponent";
import AttackerWantComponent from "./AttackerWantComponent";
import AttackerKnowComponent from "./AttackerKnowComponent";
import AttackerAchieveComponent from "./AttackerAchieveComponent";
import AboutComponent from "./AboutComponent";
import {ContactUs} from "./ContactUs";
import {SubscribeEmailJS} from "./SubscribeEmailJS";
import Background1 from './assets/wallpaper1.jpg';
import Background2 from './assets/wallpaper2.jpg';
import Background3 from './assets/wallpaper3.jpg';
import Typewriter from "./Typewriter";
import {scroller} from 'react-scroll';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown} from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {

    const scrollToSection = () => {
        scroller.scrollTo('services', {
            duration: 500,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    };

    return (
        <div>
            <section id="home" className="white-text text-center full-height mb-0"
                     style={{
                         background: `linear-gradient(rgba(19, 53, 67, 0.8), rgba(19, 53, 67, 0.8)), url(${Background2})`,
                         height: '100vh',
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         backgroundPosition: 'center center',
                     }}>
                <div>
                    <div style={{marginTop: "-120px"}} className="p-3">
                        <h3 className="font-calibri-light">
                            <Typewriter text="Do you know what is the best way to protect your machine learning model?"
                                        delay={5}/>
                        </h3>
                        <div className="d-none d-xl-block">
                            <button className="arrow-button mt-3" onClick={scrollToSection}>
                                <FontAwesomeIcon icon={faArrowDown}/>
                            </button>
                        </div>
                    </div>
                    <div style={{bottom: 30, position: "absolute", width: "100%"}}>
                        <SubscribeEmailJS/>
                    </div>
                </div>

            </section>

            <section id="description"
                     className="font-calibri-light aifence-color-light aifence-text-color mb-0">
                <DescriptionComponent/>
            </section>

            <section id="services" className="white-text font-calibri-light mb-0"
                     style={{
                         background: `linear-gradient(rgba(19, 53, 67, 0.85), rgba(19, 53, 67, 0.85)), url(${Background3})`,
                         height: '100vh',
                         backgroundSize: 'cover',
                         backgroundRepeat: 'no-repeat',
                         backgroundPosition: 'center center',
                     }}>
                <VulnerabilityComponent/>
            </section>

            <section id="services_attacker_wants" className="aifence-color-light aifence-text-color mb-0">
                <AttackerWantComponent/>
            </section>

            <section id="services_attacker_knows" className="white-text font-calibri-light mb-0">
                <AttackerKnowComponent/>
            </section>

            <section id="services_attacker_achieves"
                     className="aifence-color-light aifence-text-color aifence-li-small mb-0">
                <AttackerAchieveComponent/>
            </section>

            <section id="about" className="white-text font-calibri-light mb-0">
                <AboutComponent/>
            </section>

            <section id="contact" className="aifence-text-color white-color full-height">
                <ContactUs/>
            </section>
        </div>
    );
};

export default HomePage;