import React from 'react';
import {Col} from "react-bootstrap";
import './HomePage.css';

const PoisoningAttackPage = () => {

    return (
        <div>
            <h1 className="white-text">Poisoning Attacks</h1>
        </div>
    );
};

export default PoisoningAttackPage;