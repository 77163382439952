import './App.css';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import HomeComponent from "./components/HomeComponent";
import PerturbationAttackComponent from "./components/PerturbationAttackComponent";
import PoisoningAttackComponent from "./components/PoisoningAttackComponent";
import MembershipInferenceAttackComponent from "./components/MembershipInferenceAttackComponent";
import ModelStealingAttackComponent from "./components/ModelStealingAttackComponent";


const App = () => {
    return (
        <div>
            <BrowserRouter>
                {/*<NavbarComponent/>*/}
                <Routes>
                    <Route path="/" element={<HomeComponent/>}/>
                    <Route path="/perturbation_attack" element={<PerturbationAttackComponent/>}/>
                    <Route path="/poisoning_attack" element={<PoisoningAttackComponent/>}/>
                    <Route path="/membership_inference_attack" element={<MembershipInferenceAttackComponent/>}/>
                    <Route path="/model_stealing_attack" element={<ModelStealingAttackComponent/>}/>
                </Routes>
                {/*<FooterComponent/>*/}
            </BrowserRouter>
        </div>
    );
};

export default App;
