import React from 'react';
import {Navbar, Nav, Container, Col} from "react-bootstrap";
import './HomePage.css';


const NavBar = () => {

    return (
        <Navbar style={{backgroundColor: "#133543", position: "sticky"}} data-bs-theme="dark" fixed="top" expand="lg">
            <Container>
                <Navbar.Brand href="#home">
                    <img src={"aifence_white.PNG"} height="40" className="logo-img responsive-img" alt="Logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-4">
                    <Nav className="ml-auto">
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                        <Navbar.Brand href="#description">
                            <img src={"knaight_logo1.png"} height="20" className="logo-img responsive-img"
                                 style={{marginLeft: "10px", marginTop: "-5px"}} alt="Logo"/>
                        </Navbar.Brand>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;