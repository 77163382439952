import NavbarComponent from "../components/NavbarComponent";
import FooterComponent from "../components/FooterComponent";
import PerturbationAttackPage from "./PerturbationAttackPage";


export default function PerturbationAttackComponent() {
    return (
        <div className="aifence-website">
            <main>
                <NavbarComponent/>
                <PerturbationAttackPage/>
                <FooterComponent/>
            </main>
        </div>
    );
}