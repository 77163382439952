import NavbarComponent from "../components/NavbarComponent";
import MembershipInferenceAttackPage from "../components/MembershipInferenceAttackPage";
import FooterComponent from "../components/FooterComponent";


export default function MembershipInferenceAttackComponent() {
    return (
        <div className="aifence-website">
            <main>
                <NavbarComponent/>
                <MembershipInferenceAttackPage/>
                <FooterComponent/>
            </main>
        </div>
    );
}