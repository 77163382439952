import React from 'react';
import {Col} from "react-bootstrap";
import './HomePage.css';

const PerturbationAttackPage = () => {

    return (
        <div>
            <h1 className="white-text">Perturbation Attacks</h1>
        </div>
    );
};

export default PerturbationAttackPage;