import React from 'react';
import './HomePage.css';


const FooterComponent = () => {
    return (
        <footer>
            <div className="text-center">
                <p>© {new Date().getFullYear()} aifence</p>
            </div>
        </footer>
    );
};

export default FooterComponent;