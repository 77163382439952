export default function AttackerKnowComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-3">
                <h3 className="white-text" style={{fontSize: "18px", lineHeight: "28px", marginTop: "30px"}}>
                    What does the attacker know?
                </h3>

                <h4 className="white-text" style={{fontSize: "18px", lineHeight: "28px", marginTop: "20px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li-small"> The (clean) <span style={{color: "#F4B183"}}> training data</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li-small">The security assessment may be affected by how much knowledge
                                    the attacker has on the training data.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"> The <span style={{color: "#F4B183"}}> model</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li-small">Here we analyze how much the security assessment is impacted
                                    if the attacked has access to the target model, including trained parameters,
                                    hyperparameters, initialization and training algorithm.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"><span style={{color: "#F4B183"}}> Test data</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li-small">
                                    Several attack outcomes are highly impacted if the attacker has access to the test
                                    samples.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li-small"> In summary, attacks can range from white-box to black-box. White-box
                            attacks are very interesting as they provide conditions to make a security assessment under
                            worst-case conditions. Black-box attacks are more realistic and usually divided into
                            transfer attacks and query attacks.
                            <ul className="aifence-ul">
                                <li className="aifence-li-small"><span style={{color: "#F4B183"}}>Transfer attacks</span>: it
                                    is assumed that the attacker has partial knowledge of the training data (or a
                                    surrogate dataset) and/or target model (or a
                                    surrogate model). Then, white-box is applied to the surrogate model and after the
                                    attack is transferred to the actual target model. This is mostly related to
                                    poisoning attacks.
                                </li>
                                <li className="aifence-li-small"><span style={{color: "#F4B183"}}>Query attacks</span>: the
                                    attacker queries the model and observes
                                    the predictions to iteratively refine the attack, without exploiting any additional
                                    knowledge.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </h4>
            </div>
            <div className="d-none d-xl-block" style={{padding: "100px"}}>

                <h3 className="white-text" style={{lineHeight: "36px"}}>
                    What does the attacker know?
                </h3>

                <h4 className="white-text" style={{lineHeight: "36px", marginTop: "50px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li"> The (clean) <span style={{color: "#F4B183"}}> training data</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li">The security assessment may be affected by how much knowledge
                                    the attacker has on the training data.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"> The <span style={{color: "#F4B183"}}> model</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li">Here we analyze how much the security assessment is impacted
                                    if the attacked has access to the target model, including trained parameters,
                                    hyperparameters, initialization and training algorithm.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"><span style={{color: "#F4B183"}}> Test data</span>.
                            <ul className="aifence-ul">
                                <li className="aifence-li">
                                    Several attack outcomes are highly impacted if the attacker has access to the test
                                    samples.
                                </li>
                            </ul>
                        </li>
                        <li className="aifence-li"> In summary, attacks can range from white-box to black-box. White-box
                            attacks are very interesting as they provide conditions to make a security assessment under
                            worst-case conditions. Black-box attacks are more realistic and usually divided into
                            transfer attacks and query attacks.
                            <ul className="aifence-ul">
                                <li className="aifence-li"><span style={{color: "#F4B183"}}>Transfer attacks</span>: it
                                    is assumed that the attacker has partial knowledge of the training data (or a
                                    surrogate dataset) and/or target model (or a
                                    surrogate model). Then, white-box is applied to the surrogate model and after the
                                    attack is transferred to the actual target model. This is mostly related to
                                    poisoning attacks.
                                </li>
                                <li className="aifence-li"><span style={{color: "#F4B183"}}>Query attacks</span>: the
                                    attacker queries the model and observes
                                    the predictions to iteratively refine the attack, without exploiting any additional
                                    knowledge.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </h4>
            </div>
        </div>
    );
}