import NavbarComponent from "../components/NavbarComponent";
import HomePage from "../components/HomePage";
import FooterComponent from "../components/FooterComponent";


export default function HomeComponent() {
    return (
        <div className="aifence-website">
            <main>
                <NavbarComponent/>
                <HomePage/>
                <FooterComponent/>
            </main>
        </div>
    );
}
