export default function VulnerabilityComponent() {
    return (
        <div>
            <div className="d-block d-sm-block d-md-block d-lg-none p-3">
                <h3 className="white-text" style={{fontSize: "18px", lineHeight: "28px", marginTop: "30px"}}>
                    When does a machine learning model become vulnerable?
                </h3>

                <h4 className="white-text" style={{fontSize: "18px", lineHeight: "28px", marginTop: "20px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li-small">Creating a proprietary training data may increase the complexity of
                            the aimed solution as a
                            data mining process might be time-consuming and expensive.
                            <span style={{color: "#F4B183"}}> For these reasons, several
                            machine learning model developers adopt third-party training data or training data acquired
                                from untrusted external sources.</span>
                        </li>

                        <li className="aifence-li-small">Training a model from scratch (that is, from the random
                            initialization of its parameters) may be impracticable,
                            <span style={{color: "#F4B183"}}> leading a machine learning developer to acquire a pre-trained model
                                that could embed vulnerabilities.</span>
                        </li>

                        <li className="aifence-li-small">Training a model locally might demand an unavailable infrastructure.
                            <span style={{color: "#F4B183"}}> Thus, machine learning developers outsource the model training to untrusted third-party. </span>
                        </li>
                    </ul>
                </h4>
            </div>
            <div className="d-none d-xl-block" style={{padding: "100px"}}>

                <h3 className="white-text" style={{lineHeight: "36px"}}>
                    When does a machine learning model become vulnerable?
                </h3>

                <h4 className="white-text" style={{lineHeight: "36px", marginTop: "50px"}}>
                    <ul className="aifence-ul">
                        <li className="aifence-li">Creating a proprietary training data may increase the complexity of
                            the aimed solution as a
                            data mining process might be time-consuming and expensive.
                            <span style={{color: "#F4B183"}}> For these reasons, several
                            machine learning model developers adopt third-party training data or training data acquired
                                from untrusted external sources.</span>
                        </li>

                        <li className="aifence-li">Training a model from scratch (that is, from the random
                            initialization of its parameters) may be impracticable,
                            <span style={{color: "#F4B183"}}> leading a machine learning developer to acquire a pre-trained model
                                that could embed vulnerabilities.</span>
                        </li>

                        <li className="aifence-li">Training a model locally might demand an unavailable infrastructure.
                            <span style={{color: "#F4B183"}}> Thus, machine learning developers outsource the model training to untrusted third-party. </span>
                        </li>
                    </ul>
                </h4>
            </div>
        </div>

    );
}